<template>
  <div class="h-screen flex w-full bg-img">
    <div class="vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
        <div class="full-page-bg-color" slot="no-body">
          <div class="vx-row">
            <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
              <img alt="login" class="mx-auto" src="@/assets/images/pages/forgot-password.png">
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
              <div class="p-8">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">Confirm Your Account</h4>
                  <p>
                    Please enter the confirmation code that was emailed to you. If you were directly directly from your
                    email, this will already be entered and automatically submitted.
                  </p>
                </div>

                <vs-input class="w-full mb-8" label-placeholder="Confirmation Code" type="text" v-model="code"/>
                <vs-button class="px-4 w-full md:w-auto" to="/login" type="border">Go to Login</vs-button>
                <vs-button @click="confirmAccount" class="float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-0">
                  Confirm Account
                </vs-button>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      code: this.$route.params.code || ''
    }
  },
  created () {
    this.$vs.loading.close()
  },
  mounted() {
    this.confirmAccount()
  },
  methods: {

    async confirmAccount () {

      if (!this.code) return

      const recaptcha = await this.$recaptcha('login')

      this.$vs.loading();

      this.$store.dispatch('auth/initConfirmAccount', {code: this.code, recaptcha})
        .then(response => {
          this.$vs.loading.close();

          this.$gtag.event('auth_confirm_success', {
            event_category: 'authentication',
            event_label: 'auth_confirm_success',
          });

          if (response.message) {
            this.$router.push('/login')
          }

        })
        .catch(exception => {
          this.$vs.loading.close();

          this.$vs.notify({
            title: 'Failed to Confirm',
            text: exception.message || 'An unknown error occurred while confirming your account',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })

    }

  }
}
</script>
